import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _552233ee = () => interopDefault(import('../website/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _785c9e9f = () => interopDefault(import('../website/pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _42fe6226 = () => interopDefault(import('../website/pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _7241bb76 = () => interopDefault(import('../website/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _76bbfa06 = () => interopDefault(import('../website/pages/index.html.vue' /* webpackChunkName: "pages/index.html" */))
const _71cdfedf = () => interopDefault(import('../website/pages/order.vue' /* webpackChunkName: "pages/order" */))
const _73236767 = () => interopDefault(import('../website/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _388d6e66 = () => interopDefault(import('../website/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _1d442f40 = () => interopDefault(import('../website/pages/catalog/details/index.vue' /* webpackChunkName: "pages/catalog/details/index" */))
const _d0ae7e0c = () => interopDefault(import('../website/pages/user/orders.vue' /* webpackChunkName: "pages/user/orders" */))
const _c13d24dc = () => interopDefault(import('../website/pages/user/restore-password/index.vue' /* webpackChunkName: "pages/user/restore-password/index" */))
const _20b171d0 = () => interopDefault(import('../website/pages/catalog/details/_slug.vue' /* webpackChunkName: "pages/catalog/details/_slug" */))
const _5ad8472c = () => interopDefault(import('../website/pages/user/restore-password/_token.vue' /* webpackChunkName: "pages/user/restore-password/_token" */))
const _79cacd96 = () => interopDefault(import('../website/pages/catalog/_product_group_slug.vue' /* webpackChunkName: "pages/catalog/_product_group_slug" */))
const _59033e63 = () => interopDefault(import('../website/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _53432820 = () => interopDefault(import('../website/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _552233ee,
    name: "404"
  }, {
    path: "/cart",
    component: _785c9e9f,
    name: "cart"
  }, {
    path: "/catalog",
    component: _42fe6226,
    name: "catalog"
  }, {
    path: "/checkout",
    component: _7241bb76,
    name: "checkout"
  }, {
    path: "/index.html",
    component: _76bbfa06,
    name: "index.html"
  }, {
    path: "/order",
    component: _71cdfedf,
    name: "order"
  }, {
    path: "/search",
    component: _73236767,
    name: "search"
  }, {
    path: "/user",
    component: _388d6e66,
    name: "user"
  }, {
    path: "/catalog/details",
    component: _1d442f40,
    name: "catalog-details"
  }, {
    path: "/user/orders",
    component: _d0ae7e0c,
    name: "user-orders"
  }, {
    path: "/user/restore-password",
    component: _c13d24dc,
    name: "user-restore-password"
  }, {
    path: "/catalog/details/:slug",
    component: _20b171d0,
    name: "catalog-details-slug"
  }, {
    path: "/user/restore-password/:token?",
    component: _5ad8472c,
    name: "user-restore-password-token"
  }, {
    path: "/catalog/:product_group_slug",
    component: _79cacd96,
    name: "catalog-product_group_slug"
  }, {
    path: "/",
    component: _59033e63,
    name: "index"
  }, {
    path: "/*",
    component: _53432820,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
